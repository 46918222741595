import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AUTH_TOKEN_OVERRIDE } from '../../core/interceptors/auth/tokens';
import {
  LoggerFactory,
  MDB_LOGGER_FACTORY,
} from '../../core/observability/provider';
import { AuthToken } from '../auth/models';
import { MDB_VIRTUAL_SITE } from '../virtual-site/virtual-site.service';

import { clientSettingsResponse } from './client-settings.models';

@Injectable({
  providedIn: 'root',
})
export class ClientSettingsService {
  private readonly logger = inject<LoggerFactory>(MDB_LOGGER_FACTORY)(
    'ClientSettingsService'
  );

  endPointBase: string = '/ClientSettings';

  private http = inject(HttpClient);

  private site = inject(MDB_VIRTUAL_SITE);

  constructor() {
    this.logger.debug('Initialized');
  }

  async fetchClientSettingsAsync(
    token: AuthToken
  ): Promise<clientSettingsResponse> {
    const response = await firstValueFrom(this.post(token));

    return this.processResponse(response);
  }

  private post(
    token: AuthToken
  ): Observable<HttpResponse<clientSettingsResponse>> {
    return this.http.post<clientSettingsResponse>(
      environment.apiUrl + this.endPointBase,
      { siteUrl: this.site.url },
      {
        context: new HttpContext().set(AUTH_TOKEN_OVERRIDE, token),
        observe: 'response',
      }
    );
  }

  private processResponse(response: HttpResponse<clientSettingsResponse>) {
    let clientSettingsResponse: clientSettingsResponse;

    if (response.status === 200 && response.body) {
      clientSettingsResponse = response.body as clientSettingsResponse;
    }

    return clientSettingsResponse!;
  }
}

export { clientSettingsResponse };
